import * as React from 'react';
import {
  Create,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  SimpleFormIteratorProps,
  SelectInputProps,
  useArrayInput,
  LinearProgress,
  useDataProvider,
  maxLength,
} from 'react-admin';
import { useQuery } from 'react-query';
import DatetimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import HiddenInput from 'src/components/HiddenInput';
import useFormValues from 'src/hooks/useFormValues';
import { pricingTypes, loginTypes, organizationTypeIds, sellerIds } from './field';
import { Divider, Typography } from '@mui/material';
import { FieldValues, useFormContext } from 'react-hook-form';

export const SellerInput: React.FC<SelectInputProps> = (props) => {
  const { setValue } = useFormContext<FieldValues>();
  const { isLegalOn } = useFormValues();
  React.useEffect(() => {
    if (isLegalOn) {
      setValue('sellerId', 2);
    } else {
      setValue('sellerId', 1);
    }
  }, [isLegalOn, setValue]);
  return <SelectInput {...props} />;
};

export const JobTitleIterator: React.FC<SimpleFormIteratorProps> = (props) => {
  const { replace } = useArrayInput(props);
  const { organizationTypeId } = useFormValues();
  const dataProvider = useDataProvider();
  const [message, setMessage] = React.useState('');
  const { data, isLoading } = useQuery(
    ['jobTitlePresets', 'getOne', { id: organizationTypeId }],
    () =>
      dataProvider.getOne<{ id: string; presets: { name: string; billable: boolean }[] }>('jobTitlePresets', {
        id: organizationTypeId,
      }),
    {
      enabled: !!organizationTypeId,
    },
  );

  React.useEffect(() => {
    if (!data) {
      return;
    }
    replace(data.data.presets);
    setMessage(
      data.data.presets.length > 0
        ? '組織の設定に基づきこれらの肩書が自動的に作成されます'
        : '組織の設定に基づくと肩書は作成されません',
    );
  }, [data, replace]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <SimpleFormIterator {...props}>{props.children}</SimpleFormIterator>
      <Typography variant="caption" display="block">
        {message}
      </Typography>
    </>
  );
};

export const OrganizationCreate: React.FC = () => {
  return (
    <Create>
      <SimpleForm>
        <HiddenInput source="updatedAtMillis" />
        <BooleanInput source="isLegalOn" />

        <FormGlobalError />

        <TextInput validate={required()} source="name" />
        <TextInput validate={[required(), maxLength(28)]} source="subdomain" />
        <DatetimeInput source="trialFrom" />
        <DatetimeInput source="trialUntil" />
        <DatetimeInput source="paidFrom" />
        <DatetimeInput source="paidUntil" />
        <ReferenceArrayInput
          reference="permissions"
          source="permissionIds"
          sort={{ field: 'id', order: 'ASC' }}
          perPage={1000}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SellerInput source="sellerId" choices={sellerIds} defaultValue={1} disabled={true} />
        <SelectInput source="pricingType" choices={pricingTypes} defaultValue={null} />
        <SelectInput validate={required()} source="loginType" choices={loginTypes} defaultValue="password" />
        <SelectInput
          validate={required()}
          source="organizationTypeId"
          choices={organizationTypeIds}
          defaultValue={'1'}
        />
        <ArrayInput validate={required()} source="packs">
          <SimpleFormIterator disableReordering inline>
            <NumberInput validate={required()} source="packId" />
            <DatetimeInput validate={required()} source="validFrom" />
            <DatetimeInput source="validUntil" />
          </SimpleFormIterator>
        </ArrayInput>
        <Divider sx={{ mb: 4 }} flexItem />
        <ArrayInput source="jobTitles">
          <JobTitleIterator inline disableAdd disableRemove disableClear disableReordering>
            <TextInput source="name" disabled />
            <BooleanInput source="billable" defaultValue={false} disabled />
          </JobTitleIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
